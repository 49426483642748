import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import AppTheme from './AppTheme';
import { BrowserRouter } from "react-router-dom";
import Site from './Site';
import ScrollToTop from './App/ScrollToTop';


class App extends Component {
    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <BrowserRouter>

                    <ScrollToTop>
                        <MuiThemeProvider theme={AppTheme}>
                            <Site siteName='Breur IT' />
                        </MuiThemeProvider>
                    </ScrollToTop>

                </BrowserRouter>

            </React.Fragment>
        );
    }
}

export default App;
