import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import MetaTags from 'react-meta-tags';

const styles = theme => ({

    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            width: 900,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    hero: {
        margin: '0 auto',
        padding: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 0}px`,
    },
    heroContent: {
        maxWidth: 900,
        margin: '0 auto',
        padding: `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit * 0}px`,
    },
    text: {
        marginBottom: 10,
    },
    normalFont: {
        fontFamily: `${theme.palette.normalFont.fontFamily}`,
        fontSize: theme.palette.normalFontSize,
    },
});

const content = [
    {
        title: 'Software Ontwikkeling',
        lines: [
            'Onze core business is het ontwikkelen van software.',
            'Wij kunnen voor u een softwareproject uit handen nemen, of op locatie aan een opdracht werken.',
            'Dit kan hands-on programmeren zijn maar ook breder: opzetten van software architectuur of data model, ontwikkeling, test en documentatie.',
        ]
    },
    {
        title: 'Advies en projectbegeleiding',
        lines: [
            'Op het gebied van advies zijn er diverse mogelijkheden.',
            'Denk aan toetsing van architectuur of datamodel dat u in gedachten heeft, of de migratie van de huidige werkwijze naar Scrum.',
            'Ook bij het opzetten van een Continuous Integration / Continuous Delivery traject kunnen wij u van dienst zijn.',
        ]
    },
    {
        title: 'Vrijblijvend',
        lines: [
            'Informeer vrijblijvend naar de mogelijkheden om u te ondersteunen bij software ontwikkeling of voor advies.'
        ]
    },
]

function Services(props) {
    const { classes } = props;

    return (
        <React.Fragment>
            <MetaTags>
                <title>Diensten</title>
                <meta name="description" content="Software ontwikkeling en advies" />
            </MetaTags>

            <div className={classes.layout}>
                {
                    content.map((item, idx) => (
                        <div className={classes.hero} key={idx}>

                            <div key={idx} className={classNames(classes.heroContent, classes.text)}>
                                <Typography
                                    className={classNames(classes.text)}
                                    variant="h4"
                                    component="h4"
                                >
                                    {item.title}
                                </Typography>
                                {
                                    item.lines.map((line, idx) => (
                                        <Typography
                                            key={idx}
                                            className={classNames(classes.text, classes.normalFont)}
                                            variant="body1"
                                        >
                                            {line}

                                        </Typography>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }

            </div>
        </React.Fragment>
    );
}

export default withStyles(styles)(Services);