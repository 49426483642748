import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import SimplePopover from './SimplePopover';
import MetaTags from 'react-meta-tags';

const styles = theme => ({
    box: {
        width: 200,
    },
    boxCaption: {
        color: theme.palette.bg.main,
        backgroundColor: theme.palette.primary.main,
        padding: 8,
        minHeight: 90,
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    textNeg: {
        minHeight: 120,
        padding: 8,
        margin: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            width: 900,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    hero: {
        margin: '0 auto',
        padding: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 0}px`,
    },
    heroContent: {
        maxWidth: 900,
        margin: '0 auto',
        padding: `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit * 0}px`,
    },
    text: {
        marginBottom: 10,
    },
    normalFont: {
        fontFamily: `${theme.palette.normalFont.fontFamily}`,
        fontSize: theme.palette.normalFontSize,
    },
    pijlerContainerGrid: {
        margin: '0px 0px',
        backgroundColor: theme.palette.bg.main,
    },
});




function Pijlers(classes) {
    const pijlersData = [
        {
            title: 'Communicatie:',
            description: [
                'De klant én de opdracht begrijpen',
                'Regelmatige en duidelijke communicatie',
                'Bereikbaarheid, ook na oplevering'
            ],
        },
        {
            title: 'Onderbouwde schattingen:',
            description: [
                'realistisch',
                'wat gaan we wel en niet maken?',
                'beheersing van kosten en tijd',
            ],
        },
        {
            title: 'Kwaliteit:',
            description: [
                'nakomen van afspraken',
                'tussentijds opleveren',
                'leveren wat is afgesproken',
            ],
        },
    ];

    return (
        <div className={classes.center}>
            <SimplePopover >
                <Grid container spacing={40} className={classes.pijlerContainerGrid}>
                    {pijlersData.map((pijler, idx) => (
                        <Grid key={idx} item>

                            <div className={classes.box}>
                                <Paper>
                                    <Typography
                                        variant="h6"
                                        component="h6"
                                        align="center"
                                        className={classes.boxCaption}
                                    >
                                        {pijler.title}
                                    </Typography>
                                </Paper>

                                {pijler.description.map((text, idx) => (
                                    <Paper key={idx}>

                                        <Typography
                                            variant="h6"
                                            align="center"
                                            className={classNames(classes.textNeg)}
                                        >
                                            {text}
                                        </Typography>

                                    </Paper>
                                )
                                )
                                }
                            </div>

                        </Grid>
                    ))}
                </Grid>
            </SimplePopover>
        </div>

    );
}

const content = [
    {
        title: 'Visie',
        text: [
            'Iedere klus heeft zijn bijzonderheden en een uiteindelijk uniek resultaat. Dat maakt software ontwikkeling een interessant vakgebied.',
            'Toch zijn er veel overeenkomsten bij het tot stand komen van succesvolle projecten. Dit zijn de belangrijkste drie: '
            + 'communicatie, betrouwbare en te verantwoorden schattingen, kwaliteit van het werk.',

        ],
        after: Pijlers,
    },
    {
        title: 'Samenwerking',
        text: [
            'Breur IT zoekt altijd samenwerking wanneer de situatie daarom vraagt.',
            'Extra capaciteit kan ingezet worden via het aanwezige netwerk van IT specialisten. Specialisten die we persoonlijk kennen en daarom kunnen aanbevelen. ',
        ],
    }
]

function Company(props) {
    const { classes } = props;

    return (
        <React.Fragment>
            <MetaTags>
                <title>Bedrijf</title>
                <meta name="description" content="Visie en samenwerking" />
            </MetaTags>

            <div className={classes.layout}>
                <div className={classes.hero}>

                    {content.map((x, index) => (
                        <div key={x.title} className={classNames(classes.heroContent, classes.text)}>
                            <Typography

                                className={classes.text}
                                variant="h4"
                                component="h4"
                            >
                                {x.title}
                            </Typography>

                            {x.text.map((text, idx) => (
                                <Typography
                                    key={idx}
                                    className={classNames(classes.text, classes.normalFont)}
                                    variant="body1"
                                >
                                    {text}
                                </Typography>
                            ))}

                            {
                                x.after ?
                                    x.after(classes) : ''
                            }

                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}

export default withStyles(styles)(Company);