import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, Route } from "react-router-dom";
import MediaQuery from 'react-responsive';

const styles = theme => ({

    appBar: {
        position: 'relative',
    },
    companyTitle: {
        fontFamily: `${theme.palette.companyFont.fontFamily}`,
        fontSize: '20pt',
        letterSpacing: '1.5px',
    },
    toolbarTitle: {
        color: "inherit",
        textDecoration: "inherit",
        paddingTop: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit,
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            width: 900,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    myclass: {
        borderBottom: `1px solid ${theme.palette.bg.light}`
    }
});

const CustomLink = ({ activeStyle, children, className, to, activeOnlyWhenExact, variant, text, activeClass }) => (
    <Route path={to} exact={activeOnlyWhenExact} children={({ match }) => (
        <Typography className={className + ' ' + (match && activeClass ? activeClass : '')} variant={variant} component={Link} to={to} noWrap style={match && activeStyle}>
            {text}
        </Typography>
    )} />
);

function TopBar(props) {
    const { classes } = props;

    return (
        <React.Fragment>
            <AppBar position="static" color="primary" className={classes.appBar}>
                <Toolbar>
                    <CustomLink
                        text="Breur IT"
                        variant="h6"
                        className={`${classes.toolbarTitle} ${classes.companyTitle}`}
                        activeClass={classes.myclass}
                        activeOnlyWhenExact={true} to='/' />

                    <MediaQuery query="(min-width: 700px)">

                        <CustomLink
                            text="bedrijf"
                            variant="h6"
                            className={classes.toolbarTitle}
                            activeClass={classes.myclass}
                            activeOnlyWhenExact={false} to='/bedrijf' />

                        <CustomLink
                            text="diensten"
                            variant="h6"
                            className={classes.toolbarTitle}
                            activeClass={classes.myclass}
                            activeOnlyWhenExact={false} to='/diensten' />

                        <CustomLink
                            text="technologie"
                            variant="h6"
                            className={classes.toolbarTitle}
                            activeClass={classes.myclass}
                            activeOnlyWhenExact={false} to='/technologie' />

                        <CustomLink
                            text="over Breur IT"
                            variant="h6"
                            className={classes.toolbarTitle}
                            activeClass={classes.myclass}
                            activeOnlyWhenExact={false} to='/contact' />
                    </MediaQuery>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

TopBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TopBar);
