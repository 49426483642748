import { createMuiTheme } from '@material-ui/core/styles';

const Palette =
{
    primary: {
        main: '#A80025',  // alabama crimson
        dark: '#710000',
        light: '#E0474D',
    },
    secondary: {
        main: '#064789', // DARK CERULEAN
        dark: '#00215B',
        light: '#4B72B9',
    },
    third: {
        main: '#F1BC8A',  // PALE GOLD
        dark: '#BD8C5C',
        light: '#FFEEBA',
    },
    fourth: {
        main: '#3A4149', // ARSENIC
        dark: '#141B22',
        light: '#646C74',
    },
    bg: {
        main: '#EAEBED', // isabelline
        dark: '#B8B9BB',
        light: '#FFFFFF'
    },
    companyFont: {
        fontFamily: 'Rajdhani',
    },
    normalFont: {
        fontFamily: 'Roboto',
        fontWeight: '300', // Light
    },
    normalFontSize: '12pt',
    smallerFontSize: '9pt',
};

const AppTheme = createMuiTheme(
    {
        typography: {
            useNextVariants: true,
        },

        palette: Palette,

        overrides: {
            MuiTypography: {
                h2: {
                    color: Palette.primary.main,
                },
                h3: {
                    color: Palette.primary.main,
                },
                h4: {
                    color: Palette.primary.main,
                },
                h5: {
                    color: Palette.primary.main,
                },
                h6: {
                    color: Palette.fourth.main,
                },
                body1: {
                    color: Palette.fourth.main,
                },
            }
        },
    });


export default (AppTheme)