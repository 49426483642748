import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import React from 'react';
import MetaTags from 'react-meta-tags';
import BusinessCard from './BusinessCard';
import voorwaarden from './content/Nederland ICT Voorwaarden 2014 - NL.pdf';
import Link from '@material-ui/core/Link';

const styles = theme => ({

    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            width: 900,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    hero: {
        margin: '0 auto',
        padding: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 0}px`,
    },
    heroContent: {
        maxWidth: 900,
        margin: '0 auto',
        padding: `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit * 0}px`,
    },
    normalFont: {
        fontFamily: `${theme.palette.normalFont.fontFamily}`,
        fontSize: theme.palette.normalFontSize,
    },
    smallerFont: {
        fontFamily: `${theme.palette.normalFont.fontFamily}`,
        fontSize: theme.palette.smallerFontSize,
    },
    text: {
        marginBottom: 10,
    },
});

const content = [
    'Breur IT B.V. is geregistreerd bij de Kamer van Koophandel onder KVK-nummer 88471330.',
    'BTW id: NL864640778B01',
    //   'IBAN: NL94 KNAB 0258 8431 01',
    //  'BIC: KNABNL2H',
]

function Download() {
    return <Link target='_blank' href={voorwaarden}>Nederland ICT Voorwaarden 2014 - NL.pdf</Link>
}

function About(props) {
    const { classes } = props;

    return (
        <React.Fragment>
            <MetaTags>
                <title>Over Breur IT</title>
                <meta name="description" content="Bedrijfsinformatie, contactgegevens, algemene voorwaarden" />
                <meta name="keywords" content="Breur IT, freelance, freelance software, freelance software engineer, .NET development, fullstack" />
            </MetaTags>

            <div className={classes.layout}>
                <div className={classes.hero}>
                    <div className={classes.heroContent}>
                        <Typography
                            className={classes.text}
                            variant="h4"
                            component="h4"
                        >
                            Bedrijfsinformatie
                        </Typography>
                        {content.map((text, idx) => (
                            <Typography
                                key={idx}
                                className={classNames(classes.text, classes.normalFont)}
                                variant="body1"
                            >
                                {text}
                            </Typography>
                        ))}


                    </div>

                    <div className={classes.heroContent}>

                        <BusinessCard></BusinessCard>

                    </div>
                </div>
                <div className={classes.hero}>
                    <div className={classes.heroContent}>
                        <Typography
                            className={classes.text}
                            variant="h4"
                            component="h4"
                        >
                            Algemene voorwaarden
                        </Typography>

                        <Typography
                            className={classNames(classes.text, classes.normalFont)}
                            variant="body1"
                        >
                            Op al onze aanbiedingen en overeenkomsten zijn de Nederland ICT Voorwaarden 2014 van
                            toepassing. De voorwaarden kunt u hier downloaden:
                            <Download />

                        </Typography>

                        <Typography
                            className={classNames(classes.text, classes.smallerFont)}
                            variant="body1">

                            Breur IT heeft een kopie van deze voorwaarden aangeschaft bij Nederland ICT. Indien u deze voorwaarden ook wilt gaan hanteren in uw organisatie kunt u zich te wenden tot
                            &nbsp;<Link target='_blank' href='https://www.nldigital.nl/diensten/nederland-ict-voorwaarden/'>NL Digital</Link>
                            .
                        </Typography>

                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

export default withStyles(styles)(About);