import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
    Route,
    Link as RouterLink
} from "react-router-dom";
import Link from '@material-ui/core/Link';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import PhoneOutlined from '@material-ui/icons/PhoneOutlined';

const styles = theme => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            width: 900,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },

    footer: {
        marginTop: theme.spacing.unit * 6,
        padding: `${theme.spacing.unit * 3}px 0`,
        borderTop: `1px solid ${theme.palette.divider}`,
    },

    svgIcon: {
        display: 'inlineFlex',
        margin: '0px 5px',
        verticalAlign: 'top',
    },

    toolbarTitle: {
        color: "inherit",
        textDecoration: "inherit",
        paddingTop: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit,

    },
    bottomLinks: {
        color: theme.palette.fourth.light,
        textDecoration: "inherit",
        paddingTop: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit,

    },
    smallerFont: {
        fontFamily: `${theme.palette.normalFont.fontFamily}`,
        fontSize: theme.palette.smallerFontSize,
    },
});


const footers = [
    {
        title: 'Breur IT',
        description: ['Visie', 'Samenwerking'],
        to: '/bedrijf',
    },
    {
        title: 'Diensten',
        description: ['Software ontwikkeling', 'Advies'],
        to: '/diensten',
    },
    {
        title: 'Technologie',
        description: ['C# / .NET ontwikkeling', 'iOS en Android apps', 'Websites / -applicaties'],
        to: '/technologie',
    },
    {
        title: 'Over Breur IT',
        description: ['Bedrijfsinformatie', 'Contactgegevens', 'Algemene voorwaarden'],
        to: '/contact',
    },
];

const links = [
    {
        href: 'mailto:info@breur-it.nl',
        caption: 'info@breur-it.nl',
        target: '',
        icon: EmailOutlined,
    },
    {
        href: 'tel:+31651779493',
        caption: '+31 (0)6 51 77 94 93',
        target: '',
        icon: PhoneOutlined,
    },
]

function Footer(props) {
    const { classes } = props;

    return (
        <React.Fragment>

            <footer className={classNames(classes.layout, classes.footer)}>
                <Grid container spacing={32} justify="space-evenly">
                    {footers.map(footer => (
                        <Grid item xs key={footer.title}>
                            <Route path={footer.to} children={() => (
                                <Typography className={classes.toolbarTitle} variant="h6" noWrap component={RouterLink} to={footer.to} >
                                    {footer.title}

                                    {footer.description.map(item => (
                                        <Typography key={item} variant="body1" color="textSecondary" >
                                            {item}
                                        </Typography>
                                    ))}
                                </Typography>
                            )} />
                        </Grid>
                    ))}
                </Grid>

                <Grid container spacing={32} justify="space-evenly">
                    {links.map((lnk, idx) => (
                        <Grid item sm key={idx}>
                            <Typography className={classes.bottomLinks}
                                variant="body1"

                                align="center"
                            >

                                <Link target={lnk.target} href={lnk.href} className={classes.bottomLinks}>
                                    <lnk.icon className={classes.svgIcon}></lnk.icon>
                                    {lnk.caption}
                                </Link>
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
                <Typography
                    className={classes.smallerFont}
                    variant="body2"
                    color="textSecondary"
                    align="center"
                >

                    Deze site is gebouwd met react.js en maakt GEEN gebruik van cookies. Copyright © Breur IT B.V., 2022
                </Typography>
            </footer>

        </React.Fragment>
    );
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);