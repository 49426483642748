import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as Logo } from './logo/vectorized/logo_black.svg';
import MetaTags from 'react-meta-tags';

const styles = theme => ({

    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            width: 900,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    hero: {
        margin: '0 auto',
        padding: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 0}px`,
    },

    footer: {
        marginTop: theme.spacing.unit * 8,
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: `${theme.spacing.unit * 6}px 0`,
    },

    flexContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            flexDirection: 'row',
        },
        justifyContent: 'center',
    },

    icon: {
        width: '15vw',
        height: '15vw',
    },

    textContent: {
        margin: 'auto 0',
        padding: `${theme.spacing.unit * 2}px`,
    },

    logoContent: {
        padding: `${theme.spacing.unit * 2}px`,
        width: '40%',
        height: '40%',

        [theme.breakpoints.down(500 + theme.spacing.unit * 3 * 2)]: {
            width: '70%',
            height: '70%',
        },
        [theme.breakpoints.down(300 + theme.spacing.unit * 3 * 2)]: {
            width: '100%',
            height: '100%',
        },
        [theme.breakpoints.down(600 + theme.spacing.unit * 3 * 2)]: {
            margin: '0 auto',
        },
    },
});

function Welcome(props) {
    const { classes } = props;

    return (
        <React.Fragment>
            <main className={classes.layout}>
                <MetaTags>
                    <title>Welkom bij Breur IT</title>
                    <meta name="description" content="Welkom bij Breur IT" />
                </MetaTags>

                <div className={classes.hero} >
                    <div className={classes.flexContainer}>
                        <Typography
                            className={classes.textContent}
                            variant="h2"
                            align="center"
                            color="textSecondary"
                        >
                            Welkom bij
                        </Typography>

                        <Logo className={classes.logoContent} />
                    </div>
                </div>
            </main>
        </React.Fragment>
    );
}

export default withStyles(styles)(Welcome);