import React from 'react';
import PropTypes from 'prop-types';
import Welcome from './App/Welcome';
import { withStyles } from '@material-ui/core/styles';
import TopBar from './App/TopBar';
import Footer from './App/Footer';
import { Route } from "react-router-dom";
import Company from './App/Company';
import Services from './App/Services';
import Technology  from './App/Technology';
import About from './App/About';

const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.bg.main,
            overflowY: 'scroll',
        },
    },
});

function Site(props) {
    return (
        <React.Fragment>
            <TopBar />
            <Route exact path="/" component={Welcome} />
            <Route path="/bedrijf" component={Company} />
            <Route path="/diensten" component={Services} />
            <Route path="/technologie" component={Technology} />
            <Route path="/contact" component={About} />
            <Footer />
        </React.Fragment>
    );
}

Site.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Site);