import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import MetaTags from 'react-meta-tags';

const styles = theme => ({

    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
            width: 900,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    hero: {
        margin: '0 auto',
        padding: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 0}px`,
    },
    heroContent: {
        maxWidth: 900,
        margin: '0 auto',
        padding: `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit * 0}px`,
    },
    text: {
        marginBottom: 10,
    },
    normalFont: {
        fontFamily: `${theme.palette.normalFont.fontFamily}`,
        fontSize: theme.palette.normalFontSize,
    },
});

const content = [
    {
        title: 'C# / .NET ontwikkeling',
        text: [
            'C# is een moderne taal die toegepast kan worden voor vele vraagstukken. In de back-end, in webapplicaties en in (cross-platform) apps.',
            'Sinds het begin van het .NET framework is C# de nummer 1 taal in combinatie met dit framework.',
            'Een recente ontwikkeling is het .NET Core framework. Dit volledig herschreven framework is gemaakt om op meerdere platformen gebruikt te worden.'
        ]
    },
    {
        title: ' iOS en Android apps',
        text: [
            'Xamarin is onze eerste keuze wanneer er vraag is naar een cross-platform app voor Android en iOS.',
            'De belangrijkste reden is dat door middel van C# en .NET, bekende technologie, een native app ontwikkeld wordt. Dit heeft een groot voordeel in performance, wanneer vergeleken met Cordova/PhoneGap. ',
            'Voor de Android en iOS platformen wordt dezelfde code base gebruikt (gedeelde code), wat de kosten voor ontwikkeling voor u als klant aanzienlijk drukt. ',
            'Een andere goede keuze, en eerste keuze wanneer Javascript de voorkeur heeft, is React Native app. De app wordt (grotendeels) in Javascript geschreven, onder water worden native UI elementen gebruikt van Android of iOS. Grotere bedrijven kiezen de laatste tijd vaker voor dit type app. Instagram is een bekende React Native app.'
        ]
    },
    {
        title: 'Websites en webapplicaties',
        text: [
            'Afhankelijk van de situatie kan gekozen worden voor een ASP.NET (Core) oplossing, een Angular, of een React website. ',
            'Met ASP.NET wordt een .NET applicatie gebouwd, naar keuze in .NET Framework of .NET Core, waarbij door toepassing van Razor op efficiënte wijze gebouwd wordt. ',
            'Angular is goede en populaire keuze, zeer geschikt om een site te maken die bestaat uit herbruikbare componenten. Angular is een vrij uitgebreid framework.',
            'React, bekend van Facebook en de Instagram website, is een zeer populair framework dat zich vooral richt op de View (uit Model/View/Controller). Hierdoor is er wat meer vrijheid in de keuze van aanvullende frameworks dan bij Angular.'
        ]
    },
]

function Technology(props) {
    const { classes } = props;

    return (
        <React.Fragment>
            <MetaTags>
                <title>Technologie</title>
                <meta name="description" content="C# / .NET ontwikkeling, iOS en Android apps, Websites en webapplicaties" />
                <meta name="keywords" content="Breur IT B.V.,  C#, .NET, Xamarin, freelance, freelance software, projecten, app, app development" />
            </MetaTags>

            <div className={classes.layout}>

                {
                    content.map((item, i) => (
                        <div className={classes.hero} key={i}>
                            <div className={classNames(classes.heroContent, classes.text)}>
                                <Typography
                                    className={classes.text}
                                    variant="h4"
                                    component="h4"
                                >
                                    {item.title}
                                </Typography>

                                {item.text.map((line, idx) => (
                                    <Typography
                                        key={idx}
                                        className={classNames(classes.text, classes.normalFont)}
                                        variant="body1"
                                    >
                                        {line}
                                    </Typography>
                                ))}


                            </div>
                        </div>
                    ))
                }


            </div>
        </React.Fragment>
    );
}

export default withStyles(styles)(Technology);