import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as Logo } from './logo/svg/logo_background.svg';
import Link from '@material-ui/core/Link';
import { Paper } from '@material-ui/core';
import MediaQuery from 'react-responsive';
import Grid from '@material-ui/core/Grid';
import { SocialIcon } from 'react-social-icons';


const styles = theme => ({

    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginTop: theme.spacing.unit * 3,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            width: 500,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    businessCardContainer: {
        position: 'relative',
        width: '100%',
        paddingBottom: '64.7%', /* 1:1.5454 Aspect Ratio */
        backgroundColor: 'green',
    },
    businessCard: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
    },
    logoSpace: {
        position: 'absolute',
        top: '0',
        left: '0%',
        width: '50%',
        height: '100%',
        backgroundColor: theme.palette.primary.main,
    },
    textSpace: {
        position: 'absolute',
        top: '0',
        left: '50%',
        width: '50%',
        height: '100%',
        backgroundColor: theme.palette.bg.light,
    },
    logo: {
        position: 'absolute',
        width: '50%',
        height: '100%',
    },
    logoSmall: {
        width: '100%',
        height: '100%',
    },
    text1: {
        position: 'absolute',
        top: '25%',
        left: '55%',
        right: '2%',
    },
    text2: {
        position: 'absolute',
        top: '40%',
        left: '55%',
        right: '2%',
    },
    text3: {
        position: 'absolute',
        top: '60%',
        left: '55%',
        right: '2%',
    },
    social: {
        position: 'absolute',
        top: '22%',
        left: '85%',
    }
});

function BusinessCard(props) {
    const { classes } = props;

    return (
        <React.Fragment>
            <main className={classes.layout}>
                <MediaQuery query="(min-width: 500px)">
                    <Paper>
                        <div className={classes.businessCardContainer}>
                            <div className={classes.businessCard} >
                                <div className={classes.logoSpace} />
                                <div className={classes.textSpace} />
                                <Logo className={classes.logo} />

                                <Typography className={classes.text2}

                                    variant="body1"
                                    color="textSecondary"
                                >
                                    Freelance Software Engineer <br></br>
                                    .NET specialist
                                    {/* Balsemienlaan 74<br></br>
                                    2555 RC  Den Haag <br></br> */}
                                </Typography>


                                <Typography className={classes.text1}
                                    variant="h6"
                                    color="textSecondary"
                                    component="h6">
                                    ing. Bart Breur<br></br>
                                </Typography>


                                <Typography className={classes.text3}

                                    variant="body1"
                                    color="textSecondary"
                                >

                                    <Link href={'mailto:info@breur-it.nl'} className={classes.link} color="primary">
                                        info@breur-it.nl
                                    </Link><br></br>

                                    <Link href={'/'} className={classes.link} color="primary">
                                        www.breur-it.nl
                                    </Link><br></br>

                                    <Link href={'tel:+31651779493'} className={classes.link} color="primary">
                                        +31 (0)6 51 77 94 93
                                    </Link> <br></br>
                                </Typography>

                                <Typography
                                    className={classes.social}
                                    component="span"
                                >
                                    <SocialIcon url="https://www.linkedin.com/in/bartbreur" />
                                </Typography>
                            </div>

                        </div>
                    </Paper>
                </MediaQuery>
                <MediaQuery query="(max-width: 499px)">
                    <Grid container spacing={32} justify="space-evenly">
                        <Grid item md>
                            <Logo className={classes.logoSmall} />
                        </Grid>
                        <Grid item lg>
                            <Typography
                                variant="h6"
                                color="textSecondary"
                            >
                                ing. Bart Breur<br></br>
                            </Typography>
                            <br></br>
                            <Typography

                                variant="body1"
                                color="textSecondary"
                            >

                                Balsemienlaan 74<br></br>
                                2555 RC  Den Haag <br></br>
                            </Typography>
                            <br></br>
                            <Typography

                                variant="body1"
                                color="textSecondary"
                            >

                                <Link href={'mailto:info@breur-it.nl'} className={classes.link} color="primary">
                                    info@breur-it.nl
                                </Link><br></br>

                                <Link href={'/'} className={classes.link} color="primary">
                                    www.breur-it.nl
                                </Link><br></br>

                                <Link href={'tel:+31651779493'} className={classes.link} color="primary">
                                    +31 (6) 51 77 94 93
                                </Link> <br></br>

                                <SocialIcon url="https://www.linkedin.com/in/bartbreur" />
                            </Typography>
                        </Grid>
                    </Grid>

                </MediaQuery>
            </main>
        </React.Fragment>
    );
}

export default withStyles(styles)(BusinessCard);